/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro'

import { useEffect } from 'react';

const MainPage = () => {
    // MailerLite
    useEffect(() => {
        /* eslint-disable */
        (function (w, d, e, u, f, l, n) {
            w[f] = w[f] || function () {
                (w[f].q = w[f].q || [])
                    .push(arguments);
            }, l = d.createElement(e), l.async = 1, l.src = u,
                n = d.getElementsByTagName(e)[0], n.parentNode.insertBefore(l, n);
        })(window, document, 'script', 'https://assets.mailerlite.com/js/universal.js', 'ml');
        ml('account', '1272878');
        /* eslint-enable */
    }, []);

    return (
        <div css={styles.center}>
            <div className="ml-embedded" data-form="yscbYI"></div>
        </div>
    )
}

const styles = {
    center: css`
        margin: 20px auto;
        height: 540px;
        max-width: 540px;
    `,
}

export default MainPage;
