const singles = [
    {
        url: 'the_little_beggarman',
        title: 'the little beggarman',
        releaseDate: '2023 Oct',
        desc: [
            'Chiptune mixed with acoustic folk music \\o/ This Irish melody is my favourite and has been covered so many times (also known as "the red haired boy"), so it was imminent that I do my own interpretation. This version breathes new life into it through the nostalgia of 80s computer sounds.'
        ],
        appleLink: 'https://music.apple.com/us/album/the-little-beggarman/1705629814?i=1705629815',
        amazonLink: 'https://music.amazon.com/albums/B0CH5R8F81?marketplaceId=A3K6Y4MI8GDYMT',
        youtubeLink: 'https://music.youtube.com/watch?v=z4ain-SgqeI&si=DzQdUqZsLr-VVI13',
        //spotifyLink: 'https://open.spotify.com/track/1hKBJE2jvoJ5vgzsEz5CfK?si=aaa9df2916df4942', standalone
        spotifyLink: 'https://open.spotify.com/playlist/0uwnvZSYB09X9xr9dx8Ov1?si=6f4dfa95aa854fba', //playlist
        soundCloudLink: 'https://soundcloud.com/johnny_kilobyte/the_little_beggarman',
    },
]

export default singles;